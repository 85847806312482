import * as React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import Trusted1 from 'images/250px-Kivalliq_Air-Keewatin_Air_logo-removebg-preview.png';
import Trusted2 from 'images/fastair-logo-main.png';
import Trusted3 from 'images/perimeter-logo-main.png';
import Trusted4 from 'images/customheli-logo-main.png';
import Trusted5 from 'images/Missinippi-removebg-preview.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'styles/pages/home/trusted.scss';

const Trusted = () => {
  return (
    <div className='trusted'>
      <div className='trusted-textbox'>
        <span className='tag'>Trusted</span>
        <h1 className='title'>Trusted by companies all over</h1>
        <p className='desc'>
          Working with aircraft companies nationwide providing the highest level
          of service.
        </p>
      </div>
      <div className='trusted-by'>
        <div className='trusted-logo trusted-logo--1'>
          <LazyLoadImage src={Trusted1} alt='Keewatin Air logo' />
        </div>
        <div className='trusted-logo trusted-logo--2'>
          <LazyLoadImage src={Trusted2} alt='Fastair Logo' />
        </div>
        <div className='trusted-logo trusted-logo--3'>
          <LazyLoadImage src={Trusted3} alt='Perimeter Logo' />
        </div>
        <div className='trusted-logo trusted-logo--4'>
          <LazyLoadImage src={Trusted4} alt='Custom Heli Logo ' />
        </div>
        <div className='trusted-logo trusted-logo--5'>
          <LazyLoadImage src={Trusted5} alt='Missinippi Logo' />
        </div>
      </div>
    </div>
  );
};

export default Trusted;
