import { Link } from 'gatsby';
import * as React from 'react';

import 'styles/pages/home/services.scss';

const Services = () => {
  return (
    <div className='services'>
      <div className='services-container'>
        <div className='services-textbox'>
          <span className='tag'>Services</span>
          <h1 className='title'>Our Expertise</h1>
        </div>
        <div className='feature-boxes'>
          <article className='feature-box'>
            <div className='feature-icon'>
              <i className='uil uil-star'></i>
            </div>
            <h3 className='feature-title'>Aircraft Appraisals</h3>
            <p className='feature-text'>
              We'll give you an unbiased opinion of the value of any aircraft
              and its current condition.
            </p>
          </article>
          <article className='feature-box'>
            <div className='feature-icon'>
              <i className='uil uil-wrench'></i>
            </div>
            <h3 className='feature-title'>Helicopter Servicing</h3>
            <p className='feature-text'>
              Non-specialized repairs and maintenance on Bell 206 series and
              AS350 series helicopters.
            </p>
          </article>
          <article className='feature-box'>
            <div className='feature-icon'>
              <i className='uil uil-search'></i>
            </div>
            <h3 className='feature-title'>Inspections</h3>
            <p className='feature-text'>
              Performing your aircrafts annual inspections and pre-purchase
              inspections before you buy an aircraft.
            </p>
          </article>
          <article className='feature-box'>
            <div className='feature-icon'>
              <i className='uil uil-plane-fly'></i>
            </div>
            <h3 className='feature-title'>Airplane Servicing</h3>
            <p className='feature-text'>
              Non-specialized repairs and maintenance on Piston and Turbine
              powered airplanes with a MTOW of 5700 kg and below.
            </p>
          </article>
        </div>
        <p className='and-more'>
          ... And more! Check out all of our services below.
        </p>
        <span className='services-link-container'>
          <Link to='/services' className='services-link'>
            All Services
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Services;
