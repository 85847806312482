import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Gallery9 from 'images/Gallery1.jpg';
import Gallery2 from 'images/Gallery2.jpg';
import Gallery3 from 'images/Gallery3.jpg';
import Gallery4 from 'images/Gallery4.jpg';
import Gallery5 from 'images/Gallery5.jpg';
import Gallery6 from 'images/Gallery6.jpg';
import Gallery8 from 'images/Gallery7.jpg';
import Gallery7 from 'images/Gallery8.jpg';
import Gallery1 from 'images/Gallery9.jpg';

import 'styles/pages/home/gallery.scss';

const Gallery = () => {
  return (
    <div className='gallery'>
      <div className='gallery-images'>
        <figure className='gallery-image one'>
          <a
            href='https://www.instagram.com/mobileairservice/'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-instagram'></i>
            <LazyLoadImage src={Gallery1} alt='Tli cho airplane' />
          </a>
        </figure>
        <figure className='gallery-image two'>
          <a
            href='https://www.facebook.com/Mobile-Air-Service-Inc-1853161298242487'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-facebook'></i>
            <LazyLoadImage src={Gallery2} alt='Redbull Airplane' />
          </a>
        </figure>
        <figure className='gallery-image three'>
          <a
            href='https://www.instagram.com/mobileairservice/'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-instagram'></i>
            <LazyLoadImage
              src={Gallery3}
              alt='Aircraft with sky in background'
            />
          </a>
        </figure>
        <figure className='gallery-image four'>
          <a
            href='https://www.facebook.com/Mobile-Air-Service-Inc-1853161298242487'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-facebook'></i>
            <LazyLoadImage src={Gallery4} alt='Canada helicopter' />
          </a>
        </figure>
        <figure className='gallery-image five'>
          <a
            href='https://www.facebook.com/Mobile-Air-Service-Inc-1853161298242487'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-facebook'></i>
            <LazyLoadImage src={Gallery5} alt='Old plane' />
          </a>
        </figure>
        <figure className='gallery-image six'>
          <a
            href='https://www.instagram.com/mobileairservice/'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-instagram'></i>
            <LazyLoadImage src={Gallery6} alt='Aircraft being worked on' />
          </a>
        </figure>
        <figure className='gallery-image seven'>
          <a
            href='https://www.instagram.com/mobileairservice/'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-instagram'></i>
            <LazyLoadImage src={Gallery7} alt='Multiple aircrafts lined up' />
          </a>
        </figure>
        <figure className='gallery-image eight'>
          <a
            href='https://www.instagram.com/mobileairservice/'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-instagram'></i>
            <LazyLoadImage src={Gallery8} alt='Multiple aircrafts in hanger' />
          </a>
        </figure>
        <figure className='gallery-image nine'>
          <a
            href='https://www.facebook.com/Mobile-Air-Service-Inc-1853161298242487'
            target='_blank'
            rel='noreferrer'
            noopener='true'
          >
            <i className='uil uil-facebook'></i>
            <LazyLoadImage
              src={Gallery9}
              alt='Aircraft with engine covers on'
            />
          </a>
        </figure>
      </div>
    </div>
  );
};

export default Gallery;
