import * as React from 'react';

import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MobileHangar from 'images/mobilehangar.png';
import MobileMaintenance from 'images/mobilemaintenance.png';
import MobileAirplane from 'images/MobileAirplane.jpg';
import MobileWorking from 'images/299582950_193700133006958_4238870802152680667_n.jpg';

import 'styles/pages/home/hero.scss';

const Hero = () => {
  return (
    <div className='hero'>
      <div className='hero-container'>
        <div className='hero-textbox'>
          <h1>Keeping aircrafts in the air</h1>
          <p>Providing the highest level of service to all aircraft owners.</p>
          <Link to='/contact' className='hero-textbox-contact'>
            Get in touch
          </Link>
        </div>
        <div className='hero-images-container'>
          <div className='hero-image hero-image--1'>
            <LazyLoadImage
              className='image-1'
              src={MobileWorking}
              alt='Airplane being worked on'
            />
          </div>
          <div className='hero-image hero-image--2'>
            <LazyLoadImage
              className='image-2'
              src={MobileAirplane}
              alt='Airplane on lake'
            />
          </div>
          <div className='hero-image hero-image--3'>
            <LazyLoadImage
              className='image-3'
              src={MobileHangar}
              alt='Two airplanes in hangar'
            />
          </div>
          <div className='hero-image hero-image--4'>
            <LazyLoadImage
              className='image-4'
              src={MobileMaintenance}
              alt='Airplane with open panel'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
