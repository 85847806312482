import * as React from 'react';

import Seo from '../components/global/seo';
import Layout from '../components/global/layout';
import Hero from '../components/home/hero';
import Trusted from '../components/home/trusted';
import Services from '../components/home/services';
import Gallery from '../components/home/gallery';
import Contact from '../components/home/contact';

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Trusted />
      <Services />
      <Contact />
      <Gallery />
    </Layout>
  );
};

export const Head = () => <Seo />;

export default IndexPage;
